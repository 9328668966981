<app-header #header (scrollToNavBarEmitter)="scrollToNavBar()" id="header"></app-header>
<app-navbar #navbarComp id="navBar" (scrollToContactEmitter)="scrollToContactsView()"
                        (scrollToHomeEmitter)="scrollToHomeView()"
                        (scrollToPortfolioEmitter)="scrollToPortfolioView()"
                        (scrollToAboutEmitter)="scrollToAboutView()">
</app-navbar>
<app-about id="about" class="section"></app-about>
<app-projects-card id="portfolio" class="section"></app-projects-card>
<app-contact id="contacts" class="section"></app-contact>

