<div id="particles-js"></div>
<div class="over" id="earthContainer">
  <div class="earth">
    <div>
      <div class="night"></div>  
    </div>
  </div>
  <div class="greeting" [ngStyle]="{'left': 'calc(50% - ' + currentOffset + 'vw)'}">
    <ngx-typed-js [strings]="['Привет! Меня зовуть Бен', 'Hello! My name is Ben', 'Salve! Mi chiamo Ben', 'Hola! Me llamo Ben', '你好! 我叫 Ben']" [shuffle]="false" [typeSpeed]="60"
    [loop]="true" [cursorChar]="'_'" [backDelay]="1000" (preStringTyped)="centerText($event)">
        <span class="typing"></span>
    </ngx-typed-js>
  </div>
</div>
<div class="iconContainer over">
  <div class="personalIcon over">
    <a class="personalIconLink" href="https://github.com/ben-gray-dev">
      <i class="fa fa-github"></i>
    </a>
  </div>
  <div class="personalIcon over">
    <a class="personalIconLink" href="https://www.linkedin.com/in/ben-gray-78299b151/">
      <i class="fa fa-linkedin"></i>
    </a>
  </div> 
  <div class="personalIcon over">
    <a class="personalIconLink" href="mailto:me@bengray.org">
      <i class="fa fa-envelope"></i>
    </a>
  </div> 
</div>
<div class="over" id="arrowTopContainer">
  <div  id="arrowContainer" class="iconContainer over">
    <div class="downArrow over">
        <i [@arrowDown]="isUp ? 'topOfPath': 'bottomOfPath'" class="fa fa-chevron-down" (click)="scrollToProjectsView()"></i>
    </div> 
  </div>
</div>

