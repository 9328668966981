export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDOkeg3GObA45VQy_9O05SOUHYnckkFHYI",
    authDomain: "bengraypersonalsite.firebaseapp.com",
    databaseURL: "https://bengraypersonalsite.firebaseio.com",
    projectId: "bengraypersonalsite",
    storageBucket: "bengraypersonalsite.appspot.com",
    messagingSenderId: "336179833956",
    appId: "1:336179833956:web:eb723272e38abcb1c84371",
    measurementId: "G-NMJLBXMMCC"
  }
};
